import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1a279fab = () => interopDefault(import('../pages/About.vue' /* webpackChunkName: "" */))
const _5b9ba61c = () => interopDefault(import('../pages/Cart.vue' /* webpackChunkName: "pages/Cart" */))
const _1fefb7a2 = () => interopDefault(import('../pages/Cgu.vue' /* webpackChunkName: "pages/Cgu" */))
const _1fd388a0 = () => interopDefault(import('../pages/Cgv.vue' /* webpackChunkName: "pages/Cgv" */))
const _0ec5e70a = () => interopDefault(import('../modules/checkout/pages/Checkout.vue' /* webpackChunkName: "" */))

const _11859f7c = () => interopDefault(import('../pages/ContactUs.vue' /* webpackChunkName: "" */))
const _27252b06 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _5f65bf18 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _402d0a2d = () => interopDefault(import('../modules/customer/pages/MyAccount/MyHelpCenter.vue' /* webpackChunkName: "" */))
const _2dba2270 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyNewsletter.vue' /* webpackChunkName: "" */))
const _4604f020 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _459eedf4 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyReviews.vue' /* webpackChunkName: "" */))
const _6d73c93c = () => interopDefault(import('../modules/customer/pages/MyAccount/MyWishlist.vue' /* webpackChunkName: "" */))
const _42594ab4 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _5de3cc40 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _6e9acc9a = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _2d5ba082 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _5573d274 = () => interopDefault(import('../pages/Delivery.vue' /* webpackChunkName: "pages/Delivery" */))
const _9658a19e = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "" */))
const _6be0a038 = () => interopDefault(import('../pages/Event.vue' /* webpackChunkName: "" */))
const _f7753798 = () => interopDefault(import('../pages/Faq.vue' /* webpackChunkName: "" */))
const _3ee95b9a = () => interopDefault(import('../pages/FindOrder.vue' /* webpackChunkName: "pages/FindOrder" */))
const _46d639f5 = () => interopDefault(import('../pages/Register.vue' /* webpackChunkName: "" */))
const _668fa676 = () => interopDefault(import('../pages/Rental.vue' /* webpackChunkName: "" */))
const _03871d87 = () => interopDefault(import('../pages/Login.vue' /* webpackChunkName: "" */))
const _1d8e11c7 = () => interopDefault(import('../pages/StoreLocator.vue' /* webpackChunkName: "" */))
const _6b27f483 = () => interopDefault(import('../pages/OrderConfirmed.vue' /* webpackChunkName: "" */))
const _0508c4e1 = () => interopDefault(import('../pages/Page.vue' /* webpackChunkName: "pages/Page" */))
const _7b819678 = () => interopDefault(import('../pages/PrivacyPolicy.vue' /* webpackChunkName: "" */))
const _4a04e6b0 = () => interopDefault(import('../pages/ResetPassword.vue' /* webpackChunkName: "" */))
const _f070b4ce = () => interopDefault(import('../pages/SizeGuide.vue' /* webpackChunkName: "" */))
const _3c870e9b = () => interopDefault(import('../modules/wishlist/pages/Wishlist.vue' /* webpackChunkName: "" */))
const _f2f005e6 = () => interopDefault(import('../pages/Checkout/ExternalCheckoutThankYou.vue' /* webpackChunkName: "pages/Checkout/ExternalCheckoutThankYou" */))
const _3bf7b8a2 = () => interopDefault(import('../modules/catalog/pages/product.vue' /* webpackChunkName: "" */))
const _3e0f81ee = () => interopDefault(import('../modules/catalog/pages/category.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/a-propos",
    component: _1a279fab,
    name: "about"
  }, {
    path: "/About",
    component: _1a279fab,
    name: "About"
  }, {
    path: "/Cart",
    component: _5b9ba61c,
    name: "Cart"
  }, {
    path: "/Cgu",
    component: _1fefb7a2,
    name: "Cgu"
  }, {
    path: "/Cgv",
    component: _1fd388a0,
    name: "Cgv"
  }, {
    path: "/checkout",
    component: _0ec5e70a,
    name: "checkout"
  }, {
    path: "/collections",
    redirect: () => '/c',
    name: "Collections"
  }, {
    path: "/conditions-generales-utilisateurs",
    component: _1fefb7a2,
    name: "cgu"
  }, {
    path: "/conditions-generales-vente",
    component: _1fd388a0,
    name: "cgv"
  }, {
    path: "/contact",
    component: _11859f7c,
    name: "contact-us"
  }, {
    path: "/ContactUs",
    component: _11859f7c,
    name: "ContactUs"
  }, {
    path: "/customer",
    component: _27252b06,
    meta: {"titleLabel":"My Account"},
    name: "customer",
    children: [{
      path: "addresses-details",
      component: _5f65bf18,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details"
    }, {
      path: "my-help-center",
      component: _402d0a2d,
      meta: {"titleLabel":"My Help Center"},
      name: "customer-my-help-center"
    }, {
      path: "my-newsletter",
      component: _2dba2270,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter"
    }, {
      path: "my-profile",
      component: _4604f020,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile"
    }, {
      path: "my-reviews",
      component: _459eedf4,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews"
    }, {
      path: "my-wishlist",
      component: _6d73c93c,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist"
    }, {
      path: "order-history",
      component: _42594ab4,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history"
    }, {
      path: "addresses-details/create",
      component: _5de3cc40,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _6e9acc9a,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit"
    }, {
      path: "order-history/:orderId",
      component: _2d5ba082,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order"
    }]
  }, {
    path: "/Delivery",
    component: _5573d274,
    name: "Delivery"
  }, {
    path: "/en",
    component: _9658a19e,
    name: "home___en"
  }, {
    path: "/event",
    component: _6be0a038,
    name: "event"
  }, {
    path: "/Event",
    component: _6be0a038,
    name: "Event"
  }, {
    path: "/faq",
    component: _f7753798,
    name: "faq"
  }, {
    path: "/Faq",
    component: _f7753798,
    name: "Faq"
  }, {
    path: "/FindOrder",
    component: _3ee95b9a,
    name: "FindOrder"
  }, {
    path: "/fr",
    component: _9658a19e,
    name: "home___fr"
  }, {
    path: "/Home",
    component: _9658a19e,
    name: "Home"
  }, {
    path: "/informations-de-livraison",
    component: _5573d274,
    name: "delivery"
  }, {
    path: "/inscription",
    component: _46d639f5,
    name: "register"
  }, {
    path: "/location",
    component: _668fa676,
    name: "rental"
  }, {
    path: "/login",
    component: _03871d87,
    name: "login"
  }, {
    path: "/Login",
    component: _03871d87,
    name: "Login"
  }, {
    path: "/magasins",
    component: _1d8e11c7,
    name: "store-locator"
  }, {
    path: "/order-confirmed",
    component: _6b27f483,
    name: "order-confirmed"
  }, {
    path: "/OrderConfirmed",
    component: _6b27f483,
    name: "OrderConfirmed"
  }, {
    path: "/Page",
    component: _0508c4e1,
    name: "Page"
  }, {
    path: "/panier",
    component: _5b9ba61c,
    name: "cart"
  }, {
    path: "/privacypolicy",
    component: _7b819678,
    name: "privacy"
  }, {
    path: "/PrivacyPolicy",
    component: _7b819678,
    name: "PrivacyPolicy"
  }, {
    path: "/Register",
    component: _46d639f5,
    name: "Register"
  }, {
    path: "/Rental",
    component: _668fa676,
    name: "Rental"
  }, {
    path: "/reset-password",
    component: _4a04e6b0,
    alias: "/customer/account/createPassword",
    name: "reset-password"
  }, {
    path: "/ResetPassword",
    component: _4a04e6b0,
    name: "ResetPassword"
  }, {
    path: "/retrouver-ma-commande",
    component: _3ee95b9a,
    name: "find-order"
  }, {
    path: "/sizeguide",
    component: _f070b4ce,
    name: "size-guide"
  }, {
    path: "/SizeGuide",
    component: _f070b4ce,
    name: "SizeGuide"
  }, {
    path: "/StoreLocator",
    component: _1d8e11c7,
    name: "StoreLocator"
  }, {
    path: "/wishlist",
    component: _3c870e9b,
    name: "wishlist"
  }, {
    path: "/Checkout/ExternalCheckoutThankYou",
    component: _f2f005e6,
    name: "Checkout-ExternalCheckoutThankYou"
  }, {
    path: "/en/a-propos",
    component: _1a279fab,
    name: "about___en"
  }, {
    path: "/en/About",
    component: _1a279fab,
    name: "About___en"
  }, {
    path: "/en/Cart",
    component: _5b9ba61c,
    name: "Cart___en"
  }, {
    path: "/en/Cgu",
    component: _1fefb7a2,
    name: "Cgu___en"
  }, {
    path: "/en/Cgv",
    component: _1fd388a0,
    name: "Cgv___en"
  }, {
    path: "/en/checkout",
    component: _0ec5e70a,
    name: "checkout___en"
  }, {
    path: "/en/conditions-generales-utilisateurs",
    component: _1fefb7a2,
    name: "cgu___en"
  }, {
    path: "/en/conditions-generales-vente",
    component: _1fd388a0,
    name: "cgv___en"
  }, {
    path: "/en/contact",
    component: _11859f7c,
    name: "contact-us___en"
  }, {
    path: "/en/ContactUs",
    component: _11859f7c,
    name: "ContactUs___en"
  }, {
    path: "/en/customer",
    component: _27252b06,
    meta: {"titleLabel":"My Account"},
    name: "customer___en",
    children: [{
      path: "addresses-details",
      component: _5f65bf18,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___en"
    }, {
      path: "my-help-center",
      component: _402d0a2d,
      meta: {"titleLabel":"My Help Center"},
      name: "customer-my-help-center___en"
    }, {
      path: "my-newsletter",
      component: _2dba2270,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___en"
    }, {
      path: "my-profile",
      component: _4604f020,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___en"
    }, {
      path: "my-reviews",
      component: _459eedf4,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___en"
    }, {
      path: "my-wishlist",
      component: _6d73c93c,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___en"
    }, {
      path: "order-history",
      component: _42594ab4,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___en"
    }, {
      path: "addresses-details/create",
      component: _5de3cc40,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___en"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _6e9acc9a,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___en"
    }, {
      path: "order-history/:orderId",
      component: _2d5ba082,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___en"
    }]
  }, {
    path: "/en/Delivery",
    component: _5573d274,
    name: "Delivery___en"
  }, {
    path: "/en/event",
    component: _6be0a038,
    name: "event___en"
  }, {
    path: "/en/Event",
    component: _6be0a038,
    name: "Event___en"
  }, {
    path: "/en/faq",
    component: _f7753798,
    name: "faq___en"
  }, {
    path: "/en/Faq",
    component: _f7753798,
    name: "Faq___en"
  }, {
    path: "/en/FindOrder",
    component: _3ee95b9a,
    name: "FindOrder___en"
  }, {
    path: "/en/Home",
    component: _9658a19e,
    name: "Home___en"
  }, {
    path: "/en/informations-de-livraison",
    component: _5573d274,
    name: "delivery___en"
  }, {
    path: "/en/inscription",
    component: _46d639f5,
    name: "register___en"
  }, {
    path: "/en/location",
    component: _668fa676,
    name: "rental___en"
  }, {
    path: "/en/login",
    component: _03871d87,
    name: "login___en"
  }, {
    path: "/en/Login",
    component: _03871d87,
    name: "Login___en"
  }, {
    path: "/en/magasins",
    component: _1d8e11c7,
    name: "store-locator___en"
  }, {
    path: "/en/order-confirmed",
    component: _6b27f483,
    name: "order-confirmed___en"
  }, {
    path: "/en/OrderConfirmed",
    component: _6b27f483,
    name: "OrderConfirmed___en"
  }, {
    path: "/en/Page",
    component: _0508c4e1,
    name: "Page___en"
  }, {
    path: "/en/panier",
    component: _5b9ba61c,
    name: "cart___en"
  }, {
    path: "/en/privacypolicy",
    component: _7b819678,
    name: "privacy___en"
  }, {
    path: "/en/PrivacyPolicy",
    component: _7b819678,
    name: "PrivacyPolicy___en"
  }, {
    path: "/en/Register",
    component: _46d639f5,
    name: "Register___en"
  }, {
    path: "/en/Rental",
    component: _668fa676,
    name: "Rental___en"
  }, {
    path: "/en/reset-password",
    component: _4a04e6b0,
    alias: "/customer/account/createPassword",
    name: "reset-password___en"
  }, {
    path: "/en/ResetPassword",
    component: _4a04e6b0,
    name: "ResetPassword___en"
  }, {
    path: "/en/retrouver-ma-commande",
    component: _3ee95b9a,
    name: "find-order___en"
  }, {
    path: "/en/sizeguide",
    component: _f070b4ce,
    name: "size-guide___en"
  }, {
    path: "/en/SizeGuide",
    component: _f070b4ce,
    name: "SizeGuide___en"
  }, {
    path: "/en/StoreLocator",
    component: _1d8e11c7,
    name: "StoreLocator___en"
  }, {
    path: "/en/wishlist",
    component: _3c870e9b,
    name: "wishlist___en"
  }, {
    path: "/fr/a-propos",
    component: _1a279fab,
    name: "about___fr"
  }, {
    path: "/fr/About",
    component: _1a279fab,
    name: "About___fr"
  }, {
    path: "/fr/Cart",
    component: _5b9ba61c,
    name: "Cart___fr"
  }, {
    path: "/fr/Cgu",
    component: _1fefb7a2,
    name: "Cgu___fr"
  }, {
    path: "/fr/Cgv",
    component: _1fd388a0,
    name: "Cgv___fr"
  }, {
    path: "/fr/checkout",
    component: _0ec5e70a,
    name: "checkout___fr"
  }, {
    path: "/fr/conditions-generales-utilisateurs",
    component: _1fefb7a2,
    name: "cgu___fr"
  }, {
    path: "/fr/conditions-generales-vente",
    component: _1fd388a0,
    name: "cgv___fr"
  }, {
    path: "/fr/contact",
    component: _11859f7c,
    name: "contact-us___fr"
  }, {
    path: "/fr/ContactUs",
    component: _11859f7c,
    name: "ContactUs___fr"
  }, {
    path: "/fr/customer",
    component: _27252b06,
    meta: {"titleLabel":"My Account"},
    name: "customer___fr",
    children: [{
      path: "addresses-details",
      component: _5f65bf18,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___fr"
    }, {
      path: "my-help-center",
      component: _402d0a2d,
      meta: {"titleLabel":"My Help Center"},
      name: "customer-my-help-center___fr"
    }, {
      path: "my-newsletter",
      component: _2dba2270,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___fr"
    }, {
      path: "my-profile",
      component: _4604f020,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___fr"
    }, {
      path: "my-reviews",
      component: _459eedf4,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___fr"
    }, {
      path: "my-wishlist",
      component: _6d73c93c,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___fr"
    }, {
      path: "order-history",
      component: _42594ab4,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___fr"
    }, {
      path: "addresses-details/create",
      component: _5de3cc40,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___fr"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _6e9acc9a,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___fr"
    }, {
      path: "order-history/:orderId",
      component: _2d5ba082,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___fr"
    }]
  }, {
    path: "/fr/Delivery",
    component: _5573d274,
    name: "Delivery___fr"
  }, {
    path: "/fr/event",
    component: _6be0a038,
    name: "event___fr"
  }, {
    path: "/fr/Event",
    component: _6be0a038,
    name: "Event___fr"
  }, {
    path: "/fr/faq",
    component: _f7753798,
    name: "faq___fr"
  }, {
    path: "/fr/Faq",
    component: _f7753798,
    name: "Faq___fr"
  }, {
    path: "/fr/FindOrder",
    component: _3ee95b9a,
    name: "FindOrder___fr"
  }, {
    path: "/fr/Home",
    component: _9658a19e,
    name: "Home___fr"
  }, {
    path: "/fr/informations-de-livraison",
    component: _5573d274,
    name: "delivery___fr"
  }, {
    path: "/fr/inscription",
    component: _46d639f5,
    name: "register___fr"
  }, {
    path: "/fr/location",
    component: _668fa676,
    name: "rental___fr"
  }, {
    path: "/fr/login",
    component: _03871d87,
    name: "login___fr"
  }, {
    path: "/fr/Login",
    component: _03871d87,
    name: "Login___fr"
  }, {
    path: "/fr/magasins",
    component: _1d8e11c7,
    name: "store-locator___fr"
  }, {
    path: "/fr/order-confirmed",
    component: _6b27f483,
    name: "order-confirmed___fr"
  }, {
    path: "/fr/OrderConfirmed",
    component: _6b27f483,
    name: "OrderConfirmed___fr"
  }, {
    path: "/fr/Page",
    component: _0508c4e1,
    name: "Page___fr"
  }, {
    path: "/fr/panier",
    component: _5b9ba61c,
    name: "cart___fr"
  }, {
    path: "/fr/privacypolicy",
    component: _7b819678,
    name: "privacy___fr"
  }, {
    path: "/fr/PrivacyPolicy",
    component: _7b819678,
    name: "PrivacyPolicy___fr"
  }, {
    path: "/fr/Register",
    component: _46d639f5,
    name: "Register___fr"
  }, {
    path: "/fr/Rental",
    component: _668fa676,
    name: "Rental___fr"
  }, {
    path: "/fr/reset-password",
    component: _4a04e6b0,
    alias: "/customer/account/createPassword",
    name: "reset-password___fr"
  }, {
    path: "/fr/ResetPassword",
    component: _4a04e6b0,
    name: "ResetPassword___fr"
  }, {
    path: "/fr/retrouver-ma-commande",
    component: _3ee95b9a,
    name: "find-order___fr"
  }, {
    path: "/fr/sizeguide",
    component: _f070b4ce,
    name: "size-guide___fr"
  }, {
    path: "/fr/SizeGuide",
    component: _f070b4ce,
    name: "SizeGuide___fr"
  }, {
    path: "/fr/StoreLocator",
    component: _1d8e11c7,
    name: "StoreLocator___fr"
  }, {
    path: "/fr/wishlist",
    component: _3c870e9b,
    name: "wishlist___fr"
  }, {
    path: "/en/Checkout/ExternalCheckoutThankYou",
    component: _f2f005e6,
    name: "Checkout-ExternalCheckoutThankYou___en"
  }, {
    path: "/fr/Checkout/ExternalCheckoutThankYou",
    component: _f2f005e6,
    name: "Checkout-ExternalCheckoutThankYou___fr"
  }, {
    path: "/en/p/:id/:slug",
    component: _3bf7b8a2,
    name: "product___en"
  }, {
    path: "/fr/p/:id/:slug",
    component: _3bf7b8a2,
    name: "product___fr"
  }, {
    path: "/en/c/:slug_1/:slug_2?/:slug_3?/:slug_4?/:slug_5?",
    component: _3e0f81ee,
    name: "category___en"
  }, {
    path: "/fr/c/:slug_1/:slug_2?/:slug_3?/:slug_4?/:slug_5?",
    component: _3e0f81ee,
    name: "category___fr"
  }, {
    path: "/en/:slug+",
    component: _0508c4e1,
    name: "page___en"
  }, {
    path: "/fr/:slug+",
    component: _0508c4e1,
    name: "page___fr"
  }, {
    path: "/p/:id/:slug/",
    component: _3bf7b8a2,
    name: "product"
  }, {
    path: "/c/:slug_1/:slug_2?/:slug_3?/:slug_4?/:slug_5?",
    component: _3e0f81ee,
    name: "category"
  }, {
    path: "/",
    component: _9658a19e,
    name: "home"
  }, {
    path: "/:slug+",
    component: _0508c4e1,
    name: "page"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
